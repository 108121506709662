"use client";

import { useEffect } from "react";
import { useSession } from "next-auth/react";

export default function ToltIdentifier() {
  const { data: session } = useSession();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      session?.user?.email &&
      // @ts-ignore
      window.tolt_referral
    ) {
      // @ts-ignore
      window.tolt?.signup(session.user.email);
    }
  }, [session]);

  return null;
}
